.adobe-skycity-flex-column-psedit {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
    height: 100%;
}
.adobe-skycity-flex-column-psedit-layer {
    height: 100%;
}
.adobe-skycity-layer-info-wrapper-show-properties {
    width: auto;
    min-width: 300px;
    height: 100%;
    /* background: #eff3fd 0% 0% no-repeat padding-box; */
    z-index: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    margin-left: 10px;
    /* background-image: url(../asset/loading.gif); */
}
.adobe-skycity-layer-info-wrapper-show-properties-panel {
    /* margin-left: 10px;
    height: 100%; */
}
.adobe-skycity-layer-panel {
    overflow-y: auto;
    margin: 0 auto;
    width: 99%;
    /* width: 320px; */
    flex: 1;
    height: 90%;

}
.adobe-skycity-layer-properties {
    height: 90%;
}

.adobe-skycity-layer-properties textarea{
    width: 300px;
}
