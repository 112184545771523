.adobe-skycity-cc-browser-card {
    width: 100px;
    height: 100px;
    text-align: center;
}

.adobe-skycity-ccbrowser {
    width: 100%;
    height: 100%;
}

.adobe-skycity-ccbrowser-full {
    position: absolute;
    width: 100%;
    z-index: 3;
}
.adobe-skycity-asbrowser {
    width: 100%;
    height: 100%;
}

.adobe-skycity-asbrowser-full {
    position: absolute;
    width: 100%;
    z-index: 3;
}

.adobe-skycity-s3browser {
    width: 100%;
    height: 100%;
}

.adobe-skycity-s3browser-full {
    position: absolute;
    width: 100%;
    z-index: 3;
}

.react-spectrum-GridView-item div{
    outline: none;
}

.react-spectrum-GridView {
    height: 100%;
}
