/* skycity */
#adobe-skycity-sample-images-skycity {
    position:relative;
}

.adobe-skycity-sample-images {
    position: relative;
    width: 500px;
    height: 330px;
}
.adobe-skycity-sample-images img {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    border-radius: 12px;
}
.adobe-skycity-sample-images img.adobe-skycity-sample-images-edited {
    animation-name: cf3FadeIn;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-direction: alternate;
}
@keyframes cf3FadeIn {
    0% { opacity:0;}
    35% { opacity:0;}
    55% { opacity:1; }
    100% { opacity:1; }
}

.adobe-skycity-sample-images-caption {
    z-index: 2;
    position: absolute;
    bottom: 30px;
    color: #fff;
    background: rgba(0,0,0,.6);
    width: 100%;
    padding: .5em 0;
    margin: 0;
}
.adobe-skycity-sample-images-caption p {
    margin: 0;
    font-size: 110%;
    text-align: center;
}
.adobe-skycity-sample-images-original {
    position: absolute;
    left: 0;
    width: 100%;
}
.adobe-skycity-sample-images-edited {
    width: 100%;
    background-image: url("../asset/bg_transparent.png");
}


#adobe-skycity-sample-images-crossfade > img {
    /* width: 100%;
    height: 100%; */
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    opacity: 0;
    z-index: 0;
    -webkit-backface-visibility: hidden;
    -webkit-animation: imageAnimation 8s linear infinite;
    -moz-animation: imageAnimation 8s linear infinite;
    -o-animation: imageAnimation 8s linear infinite;
    -ms-animation: imageAnimation 8s linear infinite;
    animation: imageAnimation 8s linear infinite;
}
#adobe-skycity-sample-images-crossfade > img:nth-child(1)  {
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    -ms-animation-delay: 6s;
    animation-delay: 6s;
}
#adobe-skycity-sample-images-crossfade > img:nth-child(2) {
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -o-animation-delay: 3s;
    -ms-animation-delay: 3s;
    animation-delay: 3s;
}
#adobe-skycity-sample-images-crossfade > img:nth-child(3) {
    -webkit-animation-delay: 0;
    -moz-animation-delay: 0;
    -o-animation-delay: 0;
    -ms-animation-delay: 0;
    animation-delay: 0;
}
/* #adobe-skycity-sample-images-crossfade > img:nth-child(4)  {
    -webkit-animation-delay: 8s;
    -moz-animation-delay: 8s;
    -o-animation-delay: 8s;
    -ms-animation-delay: 8s;
    animation-delay: 8s;
}
#adobe-skycity-sample-images-crossfade > img:nth-child(5) {
    -webkit-animation-delay: 24s;
    -moz-animation-delay: 24s;
    -o-animation-delay: 24s;
    -ms-animation-delay: 24s;
    animation-delay: 24s;
} */

@keyframes imageAnimation {
    0% { opacity:1; }
    17% { opacity:1; }
    25% { opacity:0; }
    92% { opacity:0; }
    100% { opacity:1; }
}

@-webkit-keyframes imageAnimation {
    0% { opacity:1; }
    17% { opacity:1; }
    25% { opacity:0; }
    92% { opacity:0; }
    100% { opacity:1; }
}

@-moz-keyframes imageAnimation {
    0% { opacity:1; }
    17% { opacity:1; }
    25% { opacity:0; }
    92% { opacity:0; }
    100% { opacity:1; }
}

@-o-keyframes imageAnimation {
    0% { opacity:1; }
    17% { opacity:1; }
    25% { opacity:0; }
    92% { opacity:0; }
    100% { opacity:1; }
}

@-ms-keyframes imageAnimation {
    0% { opacity:1; }
    17% { opacity:1; }
    25% { opacity:0; }
    92% { opacity:0; }
    100% { opacity:1; }
}

iframe {
    border:0 solid #000;
    border-radius: 12px !important;
}
