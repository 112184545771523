.adobe-skycity-output-preview-row {
    display: flex;
    flex-direction: row;
}

.adobe-skycity-output-preview-col {
    display: flex;
    flex-direction: column;
}

.adobe-skycity-output-options-file-format {
    display: flex;
    flex-direction: column;
}

.adobe-skycity-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.adobe-skycity-options-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.adobe-skycity-option {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.adobe-skycity-options-field {
    padding-right: 10px;
}
/* .adobe-skycity-options-field {
    padding-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
} */

.adobe-skycity-layer-options-num-field {
    width: 100px;
}

.adobe-skycity-output-option-delete-button {
    padding-left: 2px;
}
