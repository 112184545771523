.adobe-skycity-asset-card img {
    cursor: pointer;
    background-image: url("../asset/bg_transparent_small.png");
    width: 300px;
}

.spectrum-Asset img {
    background-image: url("../asset/bg_transparent.png");
}

.spectrum-Dropzone {
    position: relative;
}

.spectrum-Card-title {
    max-width: 120px;
}

.spectrum-TreeView {
    height: 80%;
}

.spectrum--light .spectrum-TreeView-itemLink:hover::before, .spectrum--light .spectrum-TreeView-itemLink.is-selected::before {
    background-color: rgba(44,44,44,0.08);
    margin-top: -2px;
}

.adobe-skycity-text-layer-table-container div.react-spectrum-TableView {
    height: 300px;
}

.adobe-skycity-table-container div.react-spectrum-TableView {
    height: 600px;
}

.adobe-skycity-text-layer-table {
    width: 100%;
    overflow-x: auto;
    border: 10px;
}

.spectrum--light .spectrum-Table-body {
  width: 2000px;
}

.adobe-skycity-test-case-json {
    word-wrap: break-word;
    width: 98%;
    height: 98%;
    overflow-y: scroll;
}