html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
body {
    width: 100%;
    height: 100%;
    min-width: 350px;
}

::-webkit-scrollbar-track {
    background-color: #fff
}
::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    border: 4px solid #fff;
}
::-webkit-scrollbar-button {display:none}

.adobe-skycity-app-nav::-webkit-scrollbar-track {
    background-color: #eff3fd
}
.adobe-skycity-app-nav::-webkit-scrollbar {
    background-color: #eff3fd;
    width: 16px;
}
.adobe-skycity-app-nav::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    border: 4px solid #eff3fd;
}
.adobe-skycity-app-nav::-webkit-scrollbar-button {display:none}

#root {
    width: 100%;
    height: 100%;
}

.adobe-skycity-provider {
    width: 100%;
    height: 100%;
}

header nav {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    background-color: #323232;
    /* padding-top: 10px; */
}

header nav a {
    padding: 0 10px;
    font-size: 14px;
}

.adobe-skycity-app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 600px;
    border-top: 1px solid #e4e4e4;
}

.adobe-skycity-main {
    width: 100%;
    height: 100%;
}

.adobe-skycity-outputs {
    display: flex;
    flex-wrap: wrap;
}

.adobe-skycity-output-pane {
    width: 50%;
}

.adobe-skycity-flex-row-space-between {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.adobe-skycity-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.adobe-skycity-flex-column {
    display: flex;
    flex-direction: column;
}

.adobe-skycity-flex-row-buttons {
    /* width: 100%; */
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
}

.adobe-skycity-main-iframe-div {
    width: 100%;
    height: 100%;
    background-color: #323232;
}

.adobe-skycity-main-iframe {
    padding: 10px;
    width: 96%;
    height: 96%;
}

.adobe-skycity-main-column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.adobe-skycity-app-title {
    padding-right: 10px;
    color: white;
    /* font-size: 14pt; */
}

.adobe-skycity-page-header {
    /* flex: 0 0 auto; */
    display: flex;
    flex-direction: row;
    /* vertical-align: -webkit-baseline-middle; */
    justify-content: space-between;
}

.adobe-skycity-page-main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 0;
    height: 100%;
}

/* .adobe-skycity-header-icons {
    padding: 0 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e8e8e8;
    background-color: #323232;
} */

.adobe-skycity-header-doclinks {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    /* display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center; */
    align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e8e8e8;
    background-color: #323232;
    vertical-align: middle;
    text-align: center;
}

.adobe-skycity-header-icons {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    /* display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center; */
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e8e8e8;
    background-color: #323232;
}

.adobe-skycity-header-icon-div {
    padding: 4px;
    cursor: pointer;
}

.adobe-skycity-header-icon {
    vertical-align: -webkit-baseline-middle;
}

.adobe-skycity-nav {
    position: relative;
    width: auto;
    /* padding-bottom: 100px; */
}
.adobe-skycity-nav-wrapper {
    background: #eff3fd 0% 0% no-repeat padding-box;
    height: 100%;
    /* overflow-y: auto;
    overflow-x: hidden; */
}
.adobe-skycity-nav-wrapper::-webkit-scrollbar-track {
    background-color:#eff3fd;
}
.adobe-skycity-nav-wrapper::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:4px solid #eff3fd;
    min-height: 1em;
}
.adobe-skycity-nav-wrapper::-webkit-scrollbar-button {display:none}

.adobe-skycity-nav-wrapper-home {
    background: #eff3fd 0% 0% no-repeat padding-box;
    height: 100%;
    z-index: 2;
    /* overflow-y: scroll;
    overflow-x: hidden; */
}
.adobe-skycity-icon-button {
    padding: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.adobe-skycity-icon-button-layer-properties {
    padding: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.adobe-skycity-show {
    display: block;
}
.adobe-skycity-hide {
    display: none;
}

.adobe-skycity-nav-wrapper-show {
    width: 200px;
    height: 100%;
    background: #eff3fd 0% 0% no-repeat padding-box;
    z-index: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.adobe-skycity-nav-wrapper-hide {
    width: 25px;
    height: 100%;
    background: #eff3fd 0% 0% no-repeat padding-box;
    z-index: 0;
    opacity: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
}

.adobe-skycity-reflow-layer-info-wrapper-show {
    width: 400px;
    height: 100%;
    background: #eff3fd 0% 0% no-repeat padding-box;
    z-index: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    margin-left: 10px;
}
.adobe-skycity-layer-info-wrapper-show {
    width: 300px;
    height: 100%;
    background: #eff3fd 0% 0% no-repeat padding-box;
    z-index: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    margin-left: 10px;
}
.adobe-skycity-layer-info-wrapper-show-psedit {
    width: 300px;
    height: 100%;
    background: #eff3fd 0% 0% no-repeat padding-box;
    z-index: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    margin-left: 10px;
}

.adobe-skycity-layer-info-wrapper-hide {
    width: 0px;
    height: 100%;
    background: #eff3fd 0% 0% no-repeat padding-box;
    z-index: 0;
    opacity: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
}
.adobe-skycity-cc-browser-wrapper-show {
    display: block;
    /* z-index: 0; */
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    margin-left: 10px;
    height: 100%;
}
.adobe-skycity-cc-browser-wrapper-hide {
    display: none;
    z-index: 0;
    opacity: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    height: 0px;
}
.adobe-skycity-as-browser-wrapper-show {
    display: block;
    /* z-index: 0; */
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    margin-left: 10px;
    height: 100%;
}
.adobe-skycity-as-browser-wrapper-hide {
    display: none;
    z-index: 0;
    opacity: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    height: 0px;
}
.adobe-skycity-s3-browser-wrapper-show {
    display: block;
    /* z-index: 0; */
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    margin-left: 10px;
    height: 100%;
}
.adobe-skycity-s3-browser-wrapper-hide {
    display: none;
    z-index: 0;
    opacity: 0;
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
    height: 0px;
}

.adobe-skycity-app-nav {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.adobe-skycity-app-info {
    width: 100%;
    padding: 10px;
    /* height: 100%; */
}
.adobe-skycity-layer-info {
    position: relative;
    background: #eff3fd 0% 0% no-repeat padding-box;
    height: 100%;
}
.adobe-skycity-layer-info-wrapper {
    /* width: 150px; */
    /* min-height: 100vh; */
    height: 100%;
    width: 100%;
    /* padding: 10px; */
}
.adobe-skycity-layer-info-pane {
    width: 100%;
    height: 100%;
    text-align: center;
}

.adobe-skycity-main-pane {
    width: 100%;
    height: 100%;
    height: 100%;
    border-left: 1px solid #e4e4e4;
    background-color: #fff;
    z-index: 1;
}

.adobe-skycity-main-feature {
    width: 100%;
    padding: 10px;
    overflow: auto;
}

.adobe-skycity-home {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    min-width: 500px;
    min-height: 600px;
    overflow: auto;
}

.adobe-skycity-home-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 500px);
    grid-template-rows: auto;
    padding: 1rem;
    grid-gap: 1rem;
}

.adobe-skycity-main-img {
    width: 280px;
    height: 250px;
    margin:auto;
    margin-left: -33px;
}

.adobe-skycity-icon {
    width: 20px;
    height: 20px;
}

.adobe-skycity-original-img {
    width: auto;
    height: 528px;
    margin:auto;

    display: block;
}

.adobe-skycity-lrautotone, .adobe-skycity-preset,
.adobe-skycity-pssmartobject, .adobe-skycity-textlayer, .adobe-skycity-imagecutout {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
}

.adobe-skycity-file-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;
}

.adobe-skycity-api-detail {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.adobe-skycity-icon-image-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.adobe-skycity-icon-image {
    margin: 0 auto;
    display: block;
}

.adobe-skycity-desc-heading {
    font-size: 16px;
}

.adobe-skycity-file-name {
    margin-left: 8px;
    font-weight: 500;
}

.adobe-skycity-image-preview {
    position: relative;
    width: 802px;
    height: 528px;
}

.adobe-skycity-image-preview .spinner
{
    position: absolute;
    width: 100%;
    height: 528px;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.7);
    z-index: 100;
}

.adobe-skycity-image-preview .spinner .caption
{
    position: relative;
    font-style: italic;
    float: left;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.adobe-skycity-image-preview .adobe-skycity-image-label-left {
    position: absolute;
    top: 30px;
    left: 20px;
    background: rgba(50, 50, 50, 0.6) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    color:#F8F8F8;
    width: 77px;
    height: 27px;
    font-size: 14px;
    padding: 3px;
}

.adobe-skycity-image-label-left p {
    /* center horizontally */
    text-align: center;
    /* center vertically */
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.adobe-skycity-image-preview .adobe-skycity-image-label-right {
    position: absolute;
    top: 30px;
    right: 20px;
    background: rgba(50, 50, 50, 0.6) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    color:#F8F8F8;
    width: 68px;
    height: 27px;
    font-size: 14px;
    padding: 3px;
}

.adobe-skycity-image-label-right p {
    /* center horizontally */
    text-align: center;
    /* center vertically */
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.adobe-skycity-desc {
    margin-left: 36px;
    width: 40%;
}

.adobe-skycity-react-compare-image-handle {
    width: 14px;
    height: 14px;
    background: #ffffff;
    border: none;
    border-radius: 50%
}

img {
    animation: fadein 0.3s;
}

.adobe-skycity-multiline-text {
    animation: fadein 0.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.batch-conversion-dropzone {
    display: block;
    max-width: 100%;
    min-height: 200px;
    background-color: white;
    cursor: pointer;
}

.adobe-skycity-dropzone {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer;
}

.adobe-skycity-hide {
    display: none;
}

.adobe-skycity-space {
    width: 100px;
}

.adobe-skycity-asset-grid {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
}

.adobe-skycity-asset-card {
    padding: 2px;
    display: inline-flex;
    margin: 10px;
}

.adobe-skycity-input-file-number {
    position: absolute;
    bottom: 0;
    left: 10px;
}

.adobe-skycity-icon-upload-more {
    position: absolute;
    top: 10px;
    right: 10px;
}

.adobe-skycity-full-opacity {
    opacity: 1.0;
}

.adobe-skycity-half-opacity {
    opacity: 0.1;
}

.adobe-skycity-input-files-progress {
    position: absolute;
    z-index: 2;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

.Profile *, .Profile :after, .Profile :before {
    z-index: 3;
}

.adobe-skycity-layer-thumbnail {
    max-width: 30px;
    max-height: 30px;
    border: 1px solid #000;
    background-image: url("../asset/bg_transparent_small.png");
    vertical-align: middle;
}

.adobe-skycity-button-right {
    width: 100%;
    text-align: right;
    margin-top: 20px;
    margin-bottom: 20px;
}

.adobe-skycity-no-wrap-options {
    display: flex;
    flex-direction: row;
}

.adobe-skycity-thumbnails {
    width: 100% !important;
    height: auto !important;
    border: solid 1px black;
    background-image: url("../asset/bg_transparent.png");
}

.adobe-skycity-header-link {
    white-space: nowrap;
    padding-left: 15px;
    display: inline-block;
}

.sc-main-tag-layer-component-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}
.sc-main-tag-layer-component-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.sc-main-tag-layer-component-content-selected {
    color: #0D66D0;
    font-weight: bold;
}

.icon-space-right {
    margin-right: 10px;
}

.ps-cep-reflow-treeview {
    height: -webkit-calc(100vh - 150px);
    height: -moz-calc(100vh - 150px);
    height: calc(100vh - 150px);
    /* height: 100%;
    height: 600px; */
}
.sc-layer-thumbnail-icon {
    position: absolute;
    bottom: 1px;
    right: 1px;
    z-index: 1;
    background-color: #ccc;
    padding: 1px;
    opacity: .8;
    max-height: 20px;
}
.sc-layer-thumbnail-icon-doc {
    position: absolute;
    bottom: 1px;
    right: 0;
    z-index: 1;
    background-color: #ccc;
    padding: 1px;
    opacity: .8;
    max-height: 20px;
}
.sc-layer-thumbnail-container {
    /* margin-bottom: 2px; */
    /* margin-top: 2px; */

    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    position: relative;
}

.ps-cep-reflow-treeview {
    height: 80%;
}

.adobe-skycity-layer-thumbnail-pssmartobject {
    right: -7px;
    position: absolute;
    bottom: 0;
    vertical-align: middle;
}

.adobe-skycity-side-nav-item a {
    color: #4b9cf5;
}

.adobe-skycity-modal-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.8);
    z-index: 10;
    color: white;
    padding-top: 10%;
    text-align: center;
}

.adobe-skycity-layer-info-tag-pane {
    margin-bottom: 20px;
}

.adobe-skycity-code-preview {
    width: 100%;
    margin-top: 10px;
    background: #282c34 0 0 no-repeat padding-box;
    border-radius: 4px;
    display: inline-grid;
}

.adobe-skycity-code-pagination {
    width: 200px;
    white-space: nowrap;
}

.adobe-skycity-thumbnail-container {
    padding-top: 10px;
    width: 100%;
    height: 92%;
    /* animation: fadein 1s;
    height: 100%;*/
}

.adobe-skycity-interactive-select-container {
    padding-top: 10px;
    width: 100%;
    height: 92%;
    /* animation: fadein 1s;
    height: 100%;*/
}

.adobe-skycity-table {
    width: 100%;
    height: 200px;
}

.adobe-skycity-copyright {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.adobe-skycity-step-title {
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 4px;
}

.adobe-skycity-feature-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.adobe-skycity-feature-nav-download-tooltip {
    white-space: nowrap;
}

.adobe-skycity-sortable {
    cursor: pointer;
    text-align: left;
}
.adobe-skycity-sortable-content{
    display: flex;
    flex-direction: row;
    vertical-align: middle;
}
.adobe-skycity-sortable-content-name {
    margin: auto;
}
.adobe-skycity-center-middle {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.adobe-skycity-content-padding {
    padding: 10px;
}
.adobe-skycity-youtube-size {
    position: relative;
    width: 750px;
    height: 495px;
}
.adobe-skycity-button-center {
    padding: 10px;
    text-align: center;
}
/* .adobe-skycity-layer-panel {
    overflow-y: auto;
    margin: 0 auto;
    width: 320px;
} */
.adobe-skycity-layer-info-panel {
    position: absolute;
}
.adobe-skycity-layer-info-panel-wait {
    position: relative;
}
.adobe-skycity-thumbnails-float {
    float: left;
}
.spectrum-Search button {
    /* display: none; */
}

.adobe-skycity-onboarding-image-small {
    max-width: 500px;
    border: 1px;
    padding: 5px;
    cursor: pointer;
}

.adobe-skycity-onboarding-image-original {
    width: 100%;
}
.adobe-skycity-api-status {
    margin-left: 5px;
}

.adobe-skycity-subtitle1 {
    padding-top: 10px;
    padding-bottom: 5px;
}
.adobe-skycity-test-case {
    padding: 2px;
    margin-right: 6px;
}
.adobe-skycity-test-case-files {
    display: flex;
    flex-direction: row;
}
.adobe-skycity-test-case-files-image {
    max-width: 60px;
    max-height: 60px;
}
.adobe-skycity-test-case-files-image img {
    max-width: 60px;
    max-height: 60px;
    cursor: pointer;
    border: 1px solid #999;
    background-image: url("../asset/bg_transparent_small.png");
}
.adobe-skycity-test-case-files-image-large {
    width: 100px;
}
.adobe-skycity-test-case-files-image-large img {
    width: 100px;
    cursor: pointer;
    border: 1px solid #999;
    background-image: url("../asset/bg_transparent_small.png");
}
.adobe-skycity-test-case-large-preview {
    width: 50%;
}
.adobe-skycity-test-case-large-preview div {
    overflow-x: auto;
    padding: 5px;
}
.adobe-skycity-test-case-large-preview p {
    cursor: pointer;
}



/* #scrollbar04::-webkit-scrollbar {
    overflow:hidden;
    background: rgba(235,239,248);
    width:10px;
}
#scrollbar04::-webkit-scrollbar-track {
    background: rgba(235,239,248);
    width: 1px;
}
#scrollbar04::-webkit-scrollbar-thumb {
    background-color: rgbz(203,203,203);
    width: 1px;
    border-radius: 10px;
} */



.adobe-skycity-layer-panel::-webkit-scrollbar-track {
    background-color: #eff3fd;
}
.adobe-skycity-layer-panel::-webkit-scrollbar {
    background-color: #eff3fd;
    width: 10px;
}
.adobe-skycity-layer-panel::-webkit-scrollbar-thumb {
    background-color: rgba(203,203,203);
    border-radius: 16px;
    border: 4px solid #eff3fd;
}
.adobe-skycity-layer-panel::-webkit-scrollbar-button {display:none}

.adobe-skycity-warning {
    color: red;
    /* font-weight: bold; */
}
.adobe-skycity-test-case-output-code {
    width: 100%;
}
.adobe-skycity-red-star {
    font-weight: bolder;
    color: red;
}
